import * as Prismic from "@prismicio/client"
import { defaultLang, defaultOrdering, PrismicClient, PrismicCustomTypes } from "./prosmic"
import { CourseAgeGroup, CourseType, CourseVariant } from "./Course"
import { PrismicDocument, Query } from "@prismicio/types"
import { Ordering } from "@prismicio/client"
import { shuffle, uniq } from "lodash"
import moment from "moment"
import { getParamsFromSlug, slugifyCourse } from "./common"
import { isCourseRegistrationOpen, isSampleCourse } from "./conditions"
import cache from "../cache/cache"

export interface AuthorAndFeedbacks {
  author: PrismicDocument<any> | null
  feedbacks: PrismicDocument<any>[]
}

export const getAuthorWithFeedbacksByUid = async (documentUid: string, lang = defaultLang): Promise<AuthorAndFeedbacks> => {
  const author = await PrismicClient.getByUID(PrismicCustomTypes.author, documentUid, {
    lang,
    fetchLinks: ["technology.name", "technology.slug", "technology.description", "feedback.name", "feedback.description", "feedback.image", "feedback.sex"],
    orderings: defaultOrdering,
    pageSize: 100
  })

  if (!author) {
    return {
      author: null,
      feedbacks: []
    }
  }

  const feedbacks = await PrismicClient.get({ predicates: Prismic.predicate.at("document.type", PrismicCustomTypes.feedback), lang, pageSize: 100 })
  // @ts-ignore
  const filteredFeedbacks = (feedbacks.results || []).filter((d) => d.data.lecturer?.id === author.id)

  return {
    author,
    feedbacks: filteredFeedbacks
  }
}

export interface GetCourseListFilterOptions {
  ageGroup?: CourseAgeGroup
  type?: CourseType
  tags?: string[]
  courseType?: CourseVariant
  registrationOpenFrom?: string
  registrationOpenTo?: string
  firstPublicationDate?: string
  technology?: string
  difficulty?: number
  pageSize?: number
  orderings?: Ordering | string | (Ordering | string)[]
  fetchLinks?: string[]
  isOpen?: boolean
}

export const getCourseList = async (filterOptions?: GetCourseListFilterOptions, lang = defaultLang) => {
  const defaultFilterOptions: GetCourseListFilterOptions = { registrationOpenFrom: `${moment("2022-03-01").format("YYYY-MM-DD")}T00:00:00+0000` }

  const {
    ageGroup,
    tags,
    type,
    courseType,
    registrationOpenFrom,
    registrationOpenTo,
    firstPublicationDate,
    technology,
    difficulty,
    pageSize,
    orderings = defaultOrdering,
    fetchLinks = [
      "technology.name",
      "technology.slug",
      "technology.description",
      "technology.color",
      "technology.content",
      "author.name",
      "author.email",
      "author.position",
      "author.picture",
      "author.bio",
      "photo_gallery.uid",
      "photo_gallery.title",
      "photo_gallery.description",
      "photo_gallery.author",
      "photo_gallery.images",
      "course.title",
      "course.ageGroup",
      "course.type",
      "course.difficulty",
      "course.course_type",
      "course.technology",
      "course.capacity",
      "course.excerpt",
      "course.registration_open_from",
      "course.registration_open_to",
      "course.first_course_day",
      "course.last_course_day",
      "course.when",
      "course.where",
      "course.price",
      "course.additional_information",
      "course.content",
      "course.lecturer",
      "course.equipment_price",
      "course.equipment_info",
      "course.embed",
      "course.online_meeting_link",
      "sub_course.title"
    ]
  } = { ...(filterOptions || {}), ...defaultFilterOptions }

  return await PrismicClient.get({
    predicates: [
      ...(tags && tags.length > 0 ? [Prismic.predicate.any("document.tags", uniq(tags.map((t) => t.trim().toLowerCase() || "")))] : []),
      ...(ageGroup ? [Prismic.predicate.at("my.course.age_group", ageGroup)] : []),
      ...(type ? [Prismic.predicate.at("my.course.type", type)] : []),
      ...(courseType ? [Prismic.predicate.at("my.course.course_type", courseType)] : []),
      ...(firstPublicationDate ? [Prismic.predicate.dateAfter("document.first_publication_date", firstPublicationDate)] : []),
      ...(registrationOpenFrom ? [Prismic.predicate.dateAfter("my.course.registration_open_from", registrationOpenFrom)] : []),
      ...(registrationOpenTo ? [Prismic.predicate.dateBefore("my.course.registration_open_to", registrationOpenTo)] : []),
      ...(technology ? [Prismic.predicate.at("my.course.technology", technology)] : []),
      ...(difficulty ? [Prismic.predicate.at("my.course.difficulty", difficulty.toString())] : []),
      Prismic.predicate.at("document.type", PrismicCustomTypes.course)
    ],
    lang,
    orderings,
    pageSize: pageSize || 100,
    fetchLinks
  })
}

export const getCourseById = async (id: string, lang = defaultLang): Promise<PrismicDocument<any> | undefined> => {
  return await PrismicClient.getByID(id, {
    lang,
    orderings: defaultOrdering,
    fetchLinks: [
      "technology.name",
      "technology.slug",
      "technology.description",
      "technology.color",
      "author.name",
      "author.email",
      "author.position",
      "author.picture",
      "author.bio",
      "photo_gallery.uid",
      "photo_gallery.title",
      "photo_gallery.description",
      "photo_gallery.author",
      "photo_gallery.images",
      "course.title",
      "course.ageGroup",
      "course.type",
      "course.difficulty",
      "course.course_type",
      "course.technology",
      "course.capacity",
      "course.excerpt",
      "course.registration_open_from",
      "course.registration_open_to",
      "course.first_course_day",
      "course.last_course_day",
      "course.when",
      "course.where",
      "course.price",
      "course.additional_information",
      "course.content",
      "course.lecturer",
      "course.equipment_price",
      "course.equipment_info",
      "course.embed",
      "course.online_meeting_link",
      "sub_course.title"
    ]
  })
    .then((res) => res)
    .catch((err) => {
      console.warn(`Error when getting course by id ${id}. ${JSON.stringify(err)}`)
      return undefined
    })
}

export interface Course {
  course: PrismicDocument<any> | null
  authorWithFeedbacks: AuthorAndFeedbacks | null
  parentCourses: PrismicDocument<any>[]
}

const ALL_COURSES_CACHE_ID = "all-courses-data"

export const getCourseBySlugLegacy = async (
  slug: string,
  filterOptions?: GetCourseListFilterOptions
): Promise<{ course: PrismicDocument<Record<string, any>, string, string> | null; parentCourses: any[]; authorWithFeedbacks: AuthorAndFeedbacks | null }> => {
  const { numberId: numberIdFromSlug, id } = getParamsFromSlug(slug)
  let filteredCourse: PrismicDocument

  let allCourseRes: Query<PrismicDocument<Record<string, any>, string, string>> | undefined = undefined

  const cachedAllCourses = cache.get<Query<PrismicDocument<Record<string, any>, string, string>>>(ALL_COURSES_CACHE_ID)
  if (cachedAllCourses) {
    allCourseRes = cachedAllCourses
  } else {
    allCourseRes = await getCourseList({ pageSize: 200 })
    cache.set(ALL_COURSES_CACHE_ID, allCourseRes)
  }

  if (!id) {
    // @ts-ignore
    filteredCourse = allCourseRes?.results?.find((c) => {
      const { number_id: courseNumberId } = slugifyCourse(c)
      return Number(courseNumberId) === Number(numberIdFromSlug)
    })
  } else {
    filteredCourse = await getCourseById(id)
  }

  if (!filteredCourse?.data) {
    return {
      course: null,
      authorWithFeedbacks: null,
      parentCourses: []
    }
  }

  // TODO ? what is with uid
  const authorWithFeedbacks = filteredCourse?.data?.lecturer?.uid ? await getAuthorWithFeedbacksByUid(filteredCourse.data.lecturer.uid) : null

  let parentCourses = []
  if (isSampleCourse(filteredCourse)) {
    parentCourses = allCourseRes?.results?.filter((d) => {
      if (!id) {
        const { number_id: courseNumberId } = slugifyCourse(d)
        return Number(courseNumberId) === Number(numberIdFromSlug)
      } else {
        return d.data.sub_course?.id?.length > 0 && d.data.sub_course?.id === id
      }
    })
  }

  return {
    course: filteredCourse,
    parentCourses,
    authorWithFeedbacks
  }
}

export const getFeedbackAvatarUrl = (sex: "male" | "female", index: number): string =>
  `/images/${sex}/${sex === "male" ? "boy" : "girl"}${index === 0 ? "" : `-${index}`}.svg`

export const getTechnologyList = async (lang = defaultLang): Promise<Query> =>
  await PrismicClient.get({ predicates: Prismic.predicate.at("document.type", PrismicCustomTypes.technology), pageSize: 100, lang })
    .then((res) => res)
    .catch((err) => err)

export const getAdditionallyCourses = async (slug?: string): Promise<PrismicDocument<Record<string, any>, string, string>[]> => {
  const fetchLinks = [
    "course.title",
    "course.ageGroup",
    "course.type",
    "course.difficulty",
    "course.course_type",
    "course.technology",
    "course.capacity",
    "course.excerpt",
    "course.registration_open_from",
    "course.registration_open_to",
    "course.first_course_day",
    "course.last_course_day",
    "course.when",
    "course.where",
    "course.price",
    "course.additional_information"
  ]
  const firstPublicationDate = `${moment("2022-03-01").format("YYYY-MM-DD")}T00:00:00+0000`
  const courseType = CourseVariant.course

  const technologyList = await getTechnologyList()
  const coursesOfTechnologies = await Promise.all(
    technologyList.results.flatMap(
      async (t) =>
        await getCourseList({
          pageSize: 10,
          courseType,
          technology: t.id,
          fetchLinks,
          firstPublicationDate
        }).then((res) => res.results.filter((d) => isCourseRegistrationOpen(d) && slugifyCourse(d, true).slug !== slug && !isSampleCourse(d)))
    )
  )

  const oneOrMaxTwoOfTechnologies = coursesOfTechnologies.reduce(
    (acc: PrismicDocument<Record<string, any>, string, string>[], r: PrismicDocument<Record<string, any>, string, string>[]) => {
      if (r.length === 0) {
        return acc
      }

      if (r?.[0]) {
        return [...acc, r[0]]
      }

      return acc
    },
    []
  )

  return shuffle(oneOrMaxTwoOfTechnologies)
}
